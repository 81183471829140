@import "settings/variables.scss";

#bg-image{
    img{
        height: 540px;
        object-fit: cover;
        filter: brightness(0.60);
    }
    .rounded-10{
        border-radius: 10px 10px 0 0;
    }
}
//select
#select{
    margin-top: -150px;
    img{
        object-fit: cover;
    }
    .card-img-overlay{
        background-color: $white;
        opacity: 0.8;
        margin: 25px;
        color: #212020;
    }
    a{
        margin-top: -50px;
        background-color: $mainColor;
        transition: all 0.3s;
        padding: 10px 20px;
        &:hover{
            transition: all 0.3s;
            padding: 13px 23px;
        }
    }
}
//moederdag
.contentpage{
    background-color: $white;
    padding: 20px;
    a{
        text-decoration: none;
        color: $mainColor;
        &:hover{
            color: #212020;
            text-decoration: underline;
        }
    }
}

/*************************
SCREENS LARGER THEN MOBILE
**************************/
/* sm */
@media (min-width: 576px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 992px){
    #bg-image{
        img{
            max-height:450px;
        }
    }
    .contentpage{
        padding: 120px !important;
    }
}
