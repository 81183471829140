.searchBoxResults{
    position: absolute;
    z-index: 9999;
    padding-left:0;

    li{
        a{
            img{
                height: 75px;
                width: 55px;
                object-fit: cover
            }
        }
    }
}
