@import "settings/variables.scss";


/** FOOTER**/
.abonnement-gift{
    .abonnement-gift-text{
        max-width: 490px;
        min-height: 115px;
        vertical-align: middle;
    }
    .a-first{
        border-radius: 15px;
        color: $mainColor;
        background: $white;
        &:hover{
            color: $white;
            background: $subColor;
        }
    }
    .a-second{
        border-radius: 15px;
        color: $subColor;
        background: $white;
        &:hover{
            color: $white;
            background: $mainColor;
        }
    }
}

.usp_card{
    .usp_card_image{
        min-height: 110px;
    }

    @media only screen and (min-width: 992px){
        .card-title{
            min-height: 80px;
        }
    }
}


//carousel
#klant-review{
    .carousel-inner{
        min-height: 400px;
        background: rgba(33, 32, 32, 0.83);
        border-radius: 15px;
    }
    button{
        width: 25px;
        height: 25px;
        background: $mainColor;
        border-radius: 50%;
        border:none;
    }
}
#footer-top{
    margin-top: -80%px;
}
footer{
    font-size: 18px;
    a{
        text-decoration: none !important;
        color: $white !important;
        &:hover{
            color: #6b7803 !important;
            transition: 0.3s;
        }
    }

    .level_root.horizontal{
        padding-left: 0;

        .menu_item{
            padding: 0 5px 0 0 !important;

            &::before{
                content: '-';
            }


        }
    }

    .menuFooter{
        .level_root.horizontal{
            padding-left: 0;
            display: block;

            .menu_item{
                padding: 0 !important;

                &:before{
                    content: none;
                }
            }
        }
    }

    .privacyMenu{
        font-size: 14px;
        display: flex;
        white-space: nowrap;

        @media only screen and (max-width: 1200px){
            display: block;

            .level_root.horizontal{
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }

    .bulldotsLogo{
        display: flex;
        justify-content: flex-end;

        img{
            max-height: 30px;
        }
    }
}
.social-icons{
    width: 50px;
    height: 50px;
    border-radius: 15px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;

    &:hover{
        transition: all .3s;
        opacity:.5;
    }
}
#footer-top{
    margin-bottom: -8px;
}
/*************************
SCREENS LARGER THEN MOBILE
**************************/
/* sm */
@media (min-width: 576px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 992px){
}

