@import "settings/variables.scss";

//Inloggen
#login{
    a{
        color: #000;
        transition: all 0.3s;
        &:hover{
            color: $mainColor;
            transition: all 0.3s;
        }
    }
}
//Registreren
#register{
    input[type=checkbox]{
        border: 1px solid $mainColor;
        height: 27px;
        width: 27px;
        border-radius: 8px;
    }
    input[type=checkbox]:checked {
        background-color: $mainColor;
        transition: 1s;
    }
}
