$mainColor: #a8b915;
$subColor: #83181b;
$white: #fff;
$text-color: #000;

$tooltip-bg: $mainColor;

$font-family: "Open sans", sans-serif;

*{
    scroll-behavior: smooth;
}

body{
    font-family: "Open Sans", sans-serif!important;

    h1{
        color: $mainColor;
    }
    .gray-border{
        border: 1px solid #ebebeb;
    }
    .green-bg{
        background-color: $mainColor;
    }
    .green-border{
        border: 1px solid $mainColor;
    }
    .green-text{
        color: $mainColor;
    }
    .black-text{
        color: $text-color;
    }
    .red-bg{
        background: $subColor;
    }
    .red-border{
        border: 1px solid $subColor;
    }
    .red-text{
        color: $subColor;
    }
    .rounded-10{
        border-radius: 10px;
    }
    .z-0{
        z-index: 0;
    }
    .z-5{
        z-index: 5;
    }
    #background{
        background-color: #ebebeb;
    }
    .green-white-btn{
        background-color: $mainColor;
        border: 1px solid $mainColor;
        color: $white;
        transition: all 0.3s;
        &:hover{
            background-color: $white;
            color: $mainColor;
            transition: all 0.3s;
        }
    }
    .red-white-btn{
        background-color: $subColor;
        border: 1px solid $subColor;
        color: $white;
        transition: all 0.3s;
        &:hover{
            background-color: $white;
            color: $subColor;
            transition: all 0.3s;
        }
    }
    .checkbox-big{
        input[type=checkbox] {
            border: 1px solid $mainColor;
            height: 27px;
            width: 27px;
            min-width: 27px;
            border-radius: 8px;
        }
        input[type=checkbox]:checked {
            background-color: $mainColor;
            transition: 1s;
        }
    }
    .border-bt {
        border-bottom: 1px solid $mainColor;
        margin-bottom: 30px;
    }
    .min-height{
        min-height: 300px;
    }
    .max-height{
        max-height: 400px;
    }

    a, button, .form-check-input, .form-control{
        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }
    }

    .form-select, .form-control{
        &:focus{
            border-color: #ebebeb;
            box-shadow: none;
        }
    }

    textarea, input{
        &:focus-visible{
            outline: none !important;
        }
    }
}



//ANIMISTA
.slide-in-right {
    -webkit-animation: slide-in-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-5-11 16:8:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

//----------------------------------------

.swing-in-top-fwd {
    -webkit-animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-5-10 9:58:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}
@keyframes swing-in-top-fwd {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

