.loadingSpinner {
    top: 0;
    bottom: 0;
    left: 12px;
    right: 12px;
    border-radius: 10px;
    background-color: rgba(235, 235, 235, 0.8);
    z-index: 10;
    text-align: center;

    .loadingSpinnerImg {
        width: 80px !important;
        height: 80px !important;
        margin-top: 80px;
        border-bottom: none !important;
    }
}
