@import "settings/variables.scss";

#side-filter{
    border-radius: 10px;
    label{
        font-size: 14px;
    }
    input[type=number]{
        border: 1px solid $mainColor;
        border-radius: 5px;
        padding: 5px;
    }
    ::placeholder{
        font-size: 14px;
    }

    input[type=checkbox]{
        border: 1px solid $mainColor;
    }
    input[type=checkbox]:checked {
        background-color: $mainColor;
        transition: 1s;
    }
}
.ball{
    border-radius: 50px;
    width: 20px;
    height: 20px;
    min-width: 20px;
}
#products-filtered{
    img{
        border-bottom: 10px solid #ebebeb;
        height: 400px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px 10px 0 0 ;
    }
    .availabilityBtn{
        margin-bottom: -7%;
        border-radius: 50px;
        width: 30px;
        height: 30px;
    }
    .card{
        border-radius: 10px;
    }
    .card-overlay{
        background-color: rgba(168, 185, 21, 0.9);
        z-index: 5;
        padding: 8px;
        display: none;
    }
    .card:hover .card-overlay{
        display: block;
    }
}

//Product-Details
#p-images{
    .carousel-item{
        margin-bottom: 220px;
        position: relative;
        padding-bottom: 100%;

        img{
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .carousel-indicators{
        width: 100%!important;
        height: 200px;
        justify-content: flex-start;
        button{
            width: 100px!important;
            height: 100px!important;
            img{
                border: 5px solid $white;
            }
        }
    }
}
#p-details{
    hr{
        border-top: 1px solid $mainColor;
    }
    input[type=number]{
        border: 1px solid $mainColor;
        border-radius: 5px;
        width: 40px;
    }

    .availabilityIndicator{
        border-radius: 50px;
        width: 30px;
        height: 30px;
    }

    .variantProducts{
        .variantProductImg{
            height: 100px;
            width: 70px;
            object-fit: cover;
        }

        a{
            width: 70px;
            height: 100px;
            top: 0;
            left: 0;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
//input[type=number] {
//    -moz-appearance: textfield;
//}



/*************************
SCREENS LARGER THEN MOBILE
**************************/
/* sm */
@media (min-width: 576px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 992px){
//    #p-images{
//        .carousel-item{
//            img{
//                position: absolute;
//                object-fit: cover;
//                width: 100%;
//                height: 100%;
//            }
//        }
//
//    }
    .collapse.show-desktop {
        display: block !important;
        height: auto !important;
        visibility: visible;
    }
}

.heartbeat {
    -webkit-animation: heartbeat_product 1.5s ease-in-out infinite both;
    animation: heartbeat_product 1.5s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-9-3 16:26:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat_product {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}
@keyframes heartbeat_product {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}














