@import "settings/variables.scss";


#carouselExampleDark{
    img{
        min-height: 350px;
        max-height:400px;
        object-fit: cover;
    }
    .active{
        background: $white;
    }
    button{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid $white;
        background: transparent;
        margin-left: 3px;
    }
    .carousel-card{
        background: rgba(255,255,255,0.8);
        border-radius: 15px;
        width: 100%;
    }
}

//select->dropdown
#online-bloemenwinkel{
    select, .form-check{
        border-radius: 15px 0 0 15px;
    }
    .btnDropDown{
        border-radius: 0 15px 15px 0;
    }
}
#promo{
    background: #d3db89;
    img{
        height: 350px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px 10px 0 0 ;
    }
    a{
        margin-bottom: -15px;
        color: $white;
        background: $mainColor;
        border: 1px solid $mainColor;
        border-radius: 10px;
        &:hover{
            background: $white;
            color: $mainColor;
            transition: 0.3s;
        }
    }
    .card{
        border-radius: 10px;
    }
}

/*************************
SCREENS LARGER THEN MOBILE
**************************/
/* sm */
@media (max-width: 576px) {
    #carouselExampleDark {
        .carousel-card {
            .description{
                font-size: 14px;
            }
        }
    }
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 992px){
    #carouselExampleDark{
        .carousel-card{
            max-width:580px;
        }
        //.carousel-caption{
        //    margin-right:228px;
        //}
    }
}
