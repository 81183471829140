@media (min-width: 640px){
    .is-builder > div {
        display: block !important;
    }
}

@media (max-width: 40rem){
    .column{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.contentbuilder{
    ul,p{
        li{
            list-style: disc;
        }

        i{
            font-style: italic;
        }
    }

    ul{
        list-style: inside;
        margin-left: 20px;
    }

    ol{
        list-style: decimal;
        margin-left: 20px;
        li{
            list-style: decimal;
        }
    }

    .green-white-btn{
        color: $white !important;
        &:hover{
            color: $mainColor !important;
            text-decoration: none;
        }
    }
    .red-white-btn{
        color: $white !important;
        &:hover{
            color: $subColor !important;
            text-decoration: none;
        }
    }
}

.foto1,.foto2,.foto3,.foto4{
    margin: 1rem 0 !important;
}

.fotofull{
    width: 100vw;
    margin-left: calc(-50vw + 50%) !important;


    img{
        width: 100vw;
        min-height: 350px;
        height: 40vw;
        max-height: 600px;
        object-fit: cover;
    }
}

//disable unused buttons in contentbuilder
#_cbhtml > div.is-pop.quickadd.arrow-top.center > div:nth-child(3) > button.add-more.classic {
    display: none;
}

#_cbhtml > div.is-pop.quickadd.arrow-top.left > div:nth-child(3) > button.add-more.classic{
    display: none;
}

#_cbhtml > div.is-pop.quickadd.arrow-top.left > div.is-pop-tabs,#_cbhtml > div.is-pop.quickadd.arrow-top.center > div.is-pop-tabs{
    display: none !important;
}
