@import "settings/variables.scss";

//HAMBURGER BUTTON
.navbar-toggler {
    border-color: transparent !important;
}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(168, 185, 21, 100)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
/** HEADER **/
#mobileHeaderMenu{
    .level_root.horizontal{
        display: block;
        padding-left: 0 !important;

        .menu_item{
            padding: 0 !important;
            text-decoration: none;
        }
    }

    .phonenumber{
        color: $mainColor !important;
    }
}

#wishlist-icon{
    z-index: 10;
    top: -5px;
    right: -7px;
}

#first-nav{
    ul{
        justify-content: flex-end;

        .menu_item{
            padding: 0 0 0 1rem !important;
        }
    }
    a{
        font-size: 14px;
        color: rgba(33,32,32, 0.47);
        text-decoration: none;
        &:hover{
            color: $mainColor;
            transition: 0.3s;
        }
    }
}
#second-nav{
    p{
        font-size: 18px;
        color: #212020;
        font-weight: 500;
        span{
            color: $mainColor;
        }
    }
    ::placeholder{
        color: $mainColor;
    }
    #cart-content{
        margin-top: -17px;
    }
}
//#green-menu{
//    .btn-group{
//        border-radius: 0 0 15px 15px;
//
//        button{
//            color: $white;
//            &:hover{
//                color: #6b7803;
//            }
//        }
//    }
//
//    /* .active{
//       background: $white;
//       color: $mainColor;
//     }*/
//}
#btn-first{
    border-radius: 0 0 0 15px;
}
#btn-last{
    border-radius: 0 0 15px 0;
}
#login-btn{
    a{
        border: 1px solid $mainColor;
        color: $mainColor!important;
    }
    &:hover{
        a{
            border: 1px solid $subColor;
            color: $subColor!important;
        }
        .green-bg{
            background: $subColor;
        }
    }
}
.login-btn{
    a{
        border: 1px solid $mainColor;
        color: $mainColor;
    }
    &:hover{
        a{
            border: 1px solid $subColor;
            color: $subColor;
        }
        .green-bg{
            background: $subColor;
        }
    }
}
#menu-canvas{
    .offcanvas-body{
        background-color: rgba(168, 185, 21, 0.97);
    }
    button{
        div {
            width: 35px;
            height: 4px;
            background-color: $mainColor;
            margin: 6px 0;
        }
    }
    a{
        color: $white;
        text-decoration: none;
    }
    ::placeholder{
        color: $mainColor;
    }
}

#new-green-menu{
    margin-bottom: -40px;

    .dropdown:hover>.dropdown-menu {
        display: block;
    }

    .dropdown>.dropdown-toggle:active {
        /*Without this, clicking will make it sticky*/
        pointer-events: none;
    }

    .dropdown{

        border-radius: 0;
        background-color: $mainColor;
        border-color: $mainColor;

        &:first-child{
            border-radius: 0 0 0 15px;
        }

        &:last-child{
            border-radius: 0 0 15px 0;
        }

        color: $white;



        .dropdownHoverItem{
            color: $white;
            text-decoration: none;

            &:hover{
                color: #6b7803;
            }
        }

        .dropdown-menu{
            border-radius: 0;
            top: 37px;
            border: none;
            box-shadow: 0 10px 10px rgba(0,0,0,.2);

            a{
                color: $mainColor;

                &:hover{
                    color: #6b7803;
                    background-color: #ebebeb;
                }

                &:active{
                    background-color: $white
                }
            }
        }
    }

    .active{
        background: $white;
        color: $mainColor;
    }
}

#green-menu{
    margin-bottom: -40px;

    a{
        &:first-child{
            border-radius: 0 0 0 15px;
        }

        &:last-child{
            border-radius: 0 0 15px 0;
        }

        color: $white;
        &:hover{
            color: #6b7803;
        }
    }

    .active{
      background: $white;
      color: $mainColor;
    }
}

//breadcrumb
#bread-crumb{
    background-color: rgba(245, 245, 245, 0.92);
    padding: 20px 0;
    a{
        text-decoration: none;
        color: #6c757d;
        &:hover{
            color: $mainColor;
            transition: all 0.3s;
        }
    }

}
//Sticky Modal
#sticky-modal{
    z-index: 999999;
    .modal-dialog{
        max-width: 600px;
    }
    .modal-bg{
        background-color: rgba(33, 32, 32);
        border-radius: 15px;
    }
    .form-select, .form-check{
        @media only screen and (max-width: 576px) {
            border-radius: 15px;
        }
        border-radius: 15px 0 0 15px;
        width: 100%;
    }
    .btn-group{
        @media only screen and (max-width: 576px) {
            display: block;
            button {
                border-radius: 15px !important;
                margin-top: 10px;
            }
        }
        button{
            border-radius: 0 15px 15px 0;
            max-width: 125px;
        }
    }
    #main-btn{
        position: fixed;
        z-index: 999;
        margin-top: 150px;
        margin-right: -73px;
        transform: rotate(-90deg);
        background-color: rgba(33, 32, 32, 0.8);
        border-radius: 15px 15px 0 0;
        padding: 15px 15px 15px;
        transition: all 0.3s;
        color: $white;
        font-size: 16px;
        right: 0;
        &:hover{
            color: $mainColor;
            padding: 15px 20px 20px;
            transition: all 0.3s;
        }

        @media only screen and (max-width: 576px) {
            font-size: 12px;
            border-radius: 7px 7px 0 0;
            padding: 2px 7px 0 7px;
            margin-right: -62px;
            &:hover{
                color: $white;
                padding: 2px 7px 0 7px;
            }
        }
    }
}


/*************************
SCREENS LARGER THEN MOBILE
**************************/
/* sm */
@media (min-width: 576px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (max-width: 992px){
    #navbarNav{
        display: none;
    }
}



