.pagination{
    @media only screen and (max-width: 768px) {
        justify-content: center;
    }

    .page-item.active{
        .page-link{
            background-color: $mainColor;
            border-color: $mainColor;
        }
    }

    .page-item{
        .page-link{
            color: $mainColor;

            @media only screen and (max-width: 768px) {
                display: none;
            }
        }
    }

    .page-item:first-child .page-link{
        display: block;
    }

    .page-item:last-child .page-link{
        display: block;
    }
}
