@import "settings/variables.scss";
#winkelmand {
    .card {
        border-radius: 10px 10px 0 0;

        .cartProductImg{
            position: relative;
            width: 100%;
            height: 100%;
            padding-bottom: 150%;
            border-radius: 10px 10px 0 0;

            img {
                border-radius: 10px 10px 0 0;
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    input[type=number] {
        border: 1px solid $mainColor;
        border-radius: 5px;
        width: 40px;
    }

    .ball {
        border-radius: 50px;
        width: 30px;
        height: 30px;
        min-width: 30px;
    }

    #second-row {
        border-top: 1px solid $mainColor;
    }

    input[type=checkbox] {
        border: 1px solid $mainColor;
        height: 27px;
        width: 27px;
        min-width: 27px;
        border-radius: 8px;
    }

    input[type=checkbox]:checked {
        background-color: $mainColor;
        transition: 1s;
    }

    #extra-option {
        border-radius: 0 0 10px 10px;
    }

    .link {
        color: $white;
        text-decoration: none;
        background-color: $mainColor;
    }

    #code-bon {
        input[type=text] {
            background-color: #ebebeb;
            border-radius: 10px 0 0 10px;
        }

        button {
            background-color: $mainColor;
            color: $white;
            border-radius: 0 10px 10px 0;
            border: 1px solid $mainColor;
            transition: all 0.3s;

            &:hover {
                background-color: $white;
                color: $mainColor;
                transition: all 0.3s;
            }
        }
    }

    input[type=date] {
        background-color: #ebebeb;
        border-radius: 10px;
    }
    #cart-date{
        background-color: #f8f9fa;
       button{
           background-color: $subColor;
           color: $white;
           border: 1px solid $subColor;
           transition: all 0.3s;
           &:hover{
               background-color: $white;
               color: $subColor;
               transition: all 0.3s;
           }
       }
    }
}


/*************************
SCREENS LARGER THEN MOBILE
**************************/
/* sm */
@media (min-width: 576px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 992px){
    #winkelmand{
        .card{
            border-radius: 10px 10px 0 10px;
            .cartProductImg{
                border-radius: 10px 10px 0 10px;
                img{
                    border-radius: 10px 0 0 10px;
                }
            }
        }
        .link {
            color: #000;
            text-decoration: none;
            transition: all 0.3s;
            background-color: transparent;
            &:hover {
                color: $mainColor;
                transition: all 0.3s;
            }
        }
    }
    #second-row{
        p,{
            border-left: 1px solid $mainColor;
            padding-left: 10px;
        }
    }

}

.pulsate-fwd {
    -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
    animation: pulsate-fwd 0.5s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-23 11:41:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes pulsate-fwd {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

