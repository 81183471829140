.addedToCartAlert{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    .alert{
        border-radius: 0;
    }
    .alert-success{
        background-color: $white;
        border-color: $mainColor;
        color: $mainColor;
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(100%);
        opacity: 1;
    }
    5% {
        transform: translateX(0);
        opacity: 1;
    }
    90%{
        opacity: 1;
    }

    100%{
        opacity: 0;
    }
}

.product_popup{
    position: fixed;
    right: 20px;
    bottom: 20px;
    max-width: 200px;
    padding: 20px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
    border-radius: 10px;
    background-color: $mainColor;
    animation: 10s ease-out 0s 1 slideInFromLeft;
    opacity: 0;
    p{
        color: $white;
        font-size: 18px;
        line-height: 24px;
        b{
            font-weight: bold;
        }
    }
}

.alert{
    border-radius: 0 !important;
}

.alert.alert-success{
    background-color: white !important;
    border-color: $mainColor !important;
    color: $mainColor !important;
}

.alert.alert-danger{
    background-color: white !important;
    border-color: red !important;
    color: red !important;
}
