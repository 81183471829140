@import "settings/variables.scss";

body{
    .green-link{
        color: $mainColor;
        text-decoration: none;
        transition: all .3s;
        &:hover{
            color:#212529;
            text-decoration: underline;
            transition: all .3s;
        }
    }

    .red-link{
        color: $subColor;
        text-decoration: none;
        transition: all .3s;
        &:hover{
            color:#212529;
            text-decoration: underline;
            transition: all .3s;
        }
    }

    .black-link{
        color: #212529;
        text-decoration: underline;
        transition: all .3s;
        &:hover{
            color:$mainColor;
            text-decoration: underline;
            transition: all .3s;
        }
    }
}

