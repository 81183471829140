.range-slider, .multi-range-slider {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 4rem;
    padding-bottom: 1px;
}

.multi-range-slider .range-slider {
    margin: 0;
    pointer-events: none;
}

.range, .range2 {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
}

.range:focus, .range2:focus {
    outline: 0;
    outline-offset: -1px;
}

.range-slider::before, .range-slider::after,
.multi-range-slider::before, .multi-range-slider::after {
    position: absolute;
    font-size: 0.875rem;
    line-height: 1;
    padding: 0.25rem;
    border-radius: 0.25rem;
    color: #4b5563;
    top: 2rem;
}

.multi-range-slider .range-slider::before, .multi-range-slider .range-slider::after {
    content: none !important;
}

.range-slider::before, .multi-range-slider::before {
    left: 0;
    content: attr(data-min);
}

.range-slider::after, .multi-range-slider::after {
    right: 0;
    content: attr(data-max);
}

.range::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 1rem;
    cursor: pointer;
    border-radius: 9999px;
    background-color: #cfd8e3;
    transition: all 0.2s;
}

.range::-moz-range-track {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 1rem;
    cursor: pointer;
    border-radius: 9999px;
    background-color: #cfd8e3;
    transition: all 0.2s;
}

.range::-webkit-slider-thumb {
    z-index: 10;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    border-radius: 9999px;
    background-color: #ffffff;
    height: 2rem;
    width: 2rem;
    border-width: 4px;
    border-style: solid;
    border-color: lightgrey;
    transform: translateY(calc(-50% + 0.5rem));
    pointer-events: all;
}

.range::-moz-range-thumb {
    z-index: 10;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    border-radius: 9999px;
    background-color: #ffffff;
    height: 20px;
    width: 20px;
    border-width: 4px;
    border-style: solid;
    border-color: lightgrey;
    //transform: translateY(calc(-50% + 0.5rem));
    pointer-events: all;
}

.range2::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 1rem;
    cursor: pointer;
    border-radius: 9999px;
    background-color: #cfd8e3;
    transition: all 0.2s;
}

.range2::-moz-range-track {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 1rem;
    cursor: pointer;
    border-radius: 9999px;
    background-color: #cfd8e3;
    transition: all 0.2s;
}

.range2::-webkit-slider-thumb {
    z-index: 10;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    border-radius: 9999px;
    background-color: #ffffff;
    height: 2rem;
    width: 2rem;
    border-width: 4px;
    border-style: solid;
    border-color: lightgrey;
    transform: translateY(calc(-50% + 0.5rem));
    pointer-events: all;
}

.range2::-moz-range-thumb {
    z-index: 10;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    border-radius: 9999px;
    background-color: #ffffff;
    height: 20px;
    width: 20px;
    border-width: 4px;
    border-style: solid;
    border-color: lightgrey;
    //transform: translateY(calc(-50% + 0.5rem));
    pointer-events: all;
}


.multi-range-slider .range-slider {
    position: absolute;
}
